'use client';

import React, { useEffect, useRef, useState } from 'react';
import { Dropdown } from '@/components/dropdown';
import { Article } from './article';
import { Pagination } from '@/components/pagination';
import { Icon } from '@/components/icon';
import { RichText } from '@/components/rich-text';
import { Tile } from '@/components/tile';
import classNames from 'classnames';
import { removeDuplicatesAndSort } from '@/lib/utils/remove-duplicates-and-sort';
import { useRouter } from 'next/navigation';
import { getSizes } from '@/lib/utils/get-sizes';

export const ArticlesListing = ({
    articles,
    showDate = true,
    showMedia,
    display = 'list',
    perPage,
    filter_label,
    categories_filter_label,
    subcategories_filter_label,
    tags_filter,
    filter_type,
    filter_error_message,
    override_read_more,
    search_filter_label,
}) => {
    const categories = [];
    const years = [];
    const tags = [];
    const subcategories = [];
    const [selectedYear, setSelectedYear] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSubCategory, setSelectedSubCategory] = useState(null);
    const [filteredArticles, setFilteredArticles] = useState(articles);
    const [paginatedArticles, setPaginatedArticles] = useState(articles);
    const [filteredSubCategories, setfilteredSubCategories] = useState([]);
    const [offset, setOffset] = useState(0);
    const [pages, setPages] = useState(1);
    const [searchQuery, setSearchQuery] = useState(null);
    const articlesRef = useRef(null);
    const router = useRouter();

    useEffect(() => {
        const uniquesubs = [];
        setOffset(0);
        let searchFlag = true;
        const filtered = articles.filter((article) => {
            if (selectedYear) {
                searchFlag = article.date.indexOf(selectedYear) > -1;
            }
            if (selectedCategory) {
                if (article?.type === 'press_release')
                    searchFlag = article?.pr_type === selectedCategory;
                else
                    searchFlag =
                        article.categories.indexOf(selectedCategory) > -1;
            }
            if (selectedSubCategory) {
                searchFlag =
                    article.subcategories.indexOf(selectedSubCategory) > -1;
            }
            if (searchQuery) {
                searchFlag = searchArticle(searchQuery, article);
            }
            if (selectedCategory && selectedSubCategory) {
                searchFlag =
                    article.subcategories.indexOf(selectedSubCategory) > -1 &&
                    article.categories.indexOf(selectedCategory) > -1;
            }
            if (selectedYear && selectedCategory) {
                if (article?.type === 'press_release')
                    searchFlag =
                        article?.pr_type === selectedCategory &&
                        article.date.indexOf(selectedYear) > -1;
                else
                    searchFlag =
                        article.date.indexOf(selectedYear) > -1 &&
                        article.categories.indexOf(selectedCategory) > -1;
            }
            if (selectedYear && searchQuery) {
                if (article?.type === 'press_release')
                    searchFlag =
                        article.date.indexOf(selectedYear) > -1 &&
                        searchArticle(searchQuery, article);
                else
                    searchFlag =
                        article.date.indexOf(selectedYear) > -1 &&
                        searchArticleByTags(searchQuery, article);
            }
            if (selectedCategory && searchQuery) {
                if (article?.type === 'press_release')
                    searchFlag =
                        article?.pr_type === selectedCategory &&
                        searchArticle(searchQuery, article);
                else
                    searchFlag =
                        article.categories.indexOf(selectedCategory) > -1 &&
                        searchArticleByTags(searchQuery, article);
            }
            if (selectedYear && selectedCategory && searchQuery) {
                if (article?.type === 'press_release')
                    searchFlag =
                        article.date.indexOf(selectedYear) > -1 &&
                        article?.pr_type === selectedCategory &&
                        searchArticle(searchQuery, article);
                else
                    searchFlag =
                        article.date.indexOf(selectedYear) > -1 &&
                        article.categories.indexOf(selectedCategory) > -1 &&
                        searchArticleByTags(searchQuery, article);
            }
            if (selectedYear && selectedCategory && selectedSubCategory) {
                searchFlag =
                    article.date.indexOf(selectedYear) > -1 &&
                    article.categories.indexOf(selectedCategory) > -1 &&
                    article.subcategories.indexOf(selectedSubCategory) > -1;
            }
            return searchFlag;
        });
        articles.map((article) =>
            article.categories.includes(selectedCategory) &&
            article?.subcategories
                ? uniquesubs.push(...article.subcategories)
                : null
        );
        setfilteredSubCategories(
            removeDuplicatesAndSort(uniquesubs, subcategories_filter_label)
        );
        setFilteredArticles(filtered);
        setPages(Math.ceil(filtered.length / parseInt(perPage)));
    }, [
        articles,
        selectedYear,
        selectedCategory,
        perPage,
        searchQuery,
        selectedSubCategory,
    ]);

    useEffect(() => {
        setPaginatedArticles(
            filteredArticles.slice(offset, offset + parseInt(perPage))
        );
    }, [filteredArticles, offset]);

    const handleChangePage = (page) => {
        setOffset(page === 1 ? 0 : (page - 1) * perPage);
        if (typeof window !== 'undefined') {
            const top = articlesRef.current.offsetTop - 20;
            window.scrollTo({ top, behavior: 'smooth' });
        }
    };

    articles.forEach((article) => {
        let yearTokens = article.date.split(', ');
        //Extracting Year from JP press releases for yearTokens
        router.locale === 'jp'
            ? yearTokens.push(article.date?.split('年')[0])
            : null;
        yearTokens.length >= 1 && years.push(yearTokens[yearTokens.length - 1]);
        article.categories.forEach((category) => {
            if (category) categories.push(category);
        });
        if (article?.subcategories)
            article.subcategories.forEach((subcategory) => {
                if (subcategory) subcategories.push(subcategory);
            });
        article.tags.forEach((tag) => {
            if (tag) tags.push(tag);
        });
    });
    const uniqueYears = removeDuplicatesAndSort(years, filter_label);
    const uniqueCategories = removeDuplicatesAndSort(
        categories,
        categories_filter_label
    );
    const uniqueSubCategories = removeDuplicatesAndSort(
        subcategories,
        subcategories_filter_label
    );
    const uniqueTags = removeDuplicatesAndSort(tags);

    const handleChangeYears = (year) => {
        year === uniqueYears[0] ? setSelectedYear(null) : setSelectedYear(year);
    };

    const handleChangeCategory = (category) => {
        if (category === uniqueCategories[0]) {
            setSelectedCategory(null);
            setSelectedSubCategory(null);
        } else {
            setSelectedCategory(category);
            if (selectedCategory !== category) setfilteredSubCategories(null);
            setSelectedSubCategory(null);
        }
    };

    const handleChangeSubCategory = (subcategory) => {
        subcategory === uniqueSubCategories[0]
            ? setSelectedSubCategory(null)
            : setSelectedSubCategory(subcategory);
    };

    const searchArticle = (term, article) => {
        if (article.title?.toLowerCase().indexOf(term) > -1) {
            return true;
        }
        if (article.tags.join(' ').toLowerCase().indexOf(term) > -1) {
            return true;
        }
    };

    const searchArticleByTags = (term, article) => {
        if (article.tags.join(' ').toLowerCase().indexOf(term) > -1) {
            return true;
        }
    };

    const handleTagClick = (e, tag) => {
        e.preventDefault();
        if (tag.toLowerCase() === searchQuery) {
            setSearchQuery(null);
        } else {
            setSearchQuery(tag.toLowerCase());
        }
    };

    return (
        <div ref={articlesRef} className={`articles articles--${display}`}>
            {filter_type === 'dropdown' && (
                <>
                    {tags_filter && (
                        <div className="grid-lg-4 grid-2 mb-14">
                            {uniqueTags.map((tag, key) => {
                                const article = articles.find(
                                    (article) =>
                                        article.tags
                                            .join(' ')
                                            .toLowerCase()
                                            .indexOf(tag.toLowerCase()) > -1
                                );
                                return (
                                    <a
                                        onClick={(e) => handleTagClick(e, tag)}
                                        className={classNames(
                                            'articles__filter-tag',
                                            {
                                                [`active`]:
                                                    searchQuery ===
                                                    tag.toLowerCase(),
                                            }
                                        )}
                                        key={key}
                                    >
                                        <Tile
                                            media={article?.media || null}
                                            title={{ value: tag }}
                                            variant={2}
                                            type={'filter'}
                                            sizes={getSizes({
                                                desktop: 4,
                                                tablet: 8,
                                            })}
                                        />
                                    </a>
                                );
                            })}
                        </div>
                    )}
                    <div className="articles__filters">
                        {showDate && uniqueYears && uniqueYears.length > 0 && (
                            <div className="articles__filter">
                                <Dropdown
                                    className="articles__dropdown"
                                    data={uniqueYears}
                                    onSelectChange={handleChangeYears}
                                />
                            </div>
                        )}
                        {uniqueCategories && uniqueCategories.length > 0 && (
                            <div className="articles__filter">
                                <Dropdown
                                    className="articles__dropdown"
                                    data={uniqueCategories}
                                    onSelectChange={handleChangeCategory}
                                />
                            </div>
                        )}
                        {selectedCategory &&
                            filteredSubCategories &&
                            filteredSubCategories.length > 1 && (
                                <div className="articles__filter">
                                    <Dropdown
                                        className="articles__dropdown"
                                        data={filteredSubCategories}
                                        onSelectChange={handleChangeSubCategory}
                                    />
                                </div>
                            )}
                    </div>
                </>
            )}
            {(filter_type === 'text' || articles[0]?.search) && (
                <div className="form form--lg mb-14">
                    <div className="form__group">
                        <div className="form__validation">
                            <input
                                name="search"
                                type="text"
                                placeholder={
                                    articles[0]?.search
                                        ? search_filter_label
                                        : filter_label
                                }
                                className="form__input form__input--search"
                                onChange={(e) =>
                                    setSearchQuery(e.target.value.toLowerCase())
                                }
                            />
                            <Icon
                                icon={'fa-solid fa-magnifying-glass'}
                                className="form__search"
                            />
                        </div>
                    </div>
                </div>
            )}
            <div className={`articles__display`}>
                {paginatedArticles.length > 0 &&
                    paginatedArticles.map((article, index) => {
                        const {
                            id,
                            codename,
                            title,
                            description,
                            media,
                            links,
                            date,
                            tags,
                            categories,
                        } = article;
                        return (
                            <Article
                                id={id}
                                codename={codename}
                                display={display}
                                key={index}
                                title={title}
                                description={description}
                                media={showMedia ? media : null}
                                links={links}
                                date={date}
                                tags={tags}
                                categories={categories}
                                override_read_more={override_read_more}
                            />
                        );
                    })}
            </div>
            {paginatedArticles.length === 0 &&
                (selectedYear || selectedCategory || searchQuery) && (
                    <RichText
                        className="richtext__error"
                        data={filter_error_message}
                    />
                )}
            {pages > 1 && (
                <Pagination
                    className={'mt-14'}
                    pages={pages}
                    onChangePage={handleChangePage}
                />
            )}
        </div>
    );
};
