// remove duplicates and sort array

export const removeDuplicatesAndSort = (array, defaultValue) => {
  if(!array.length)
    return null;
  let uniques = array.filter((item, pos) => {
    return array.indexOf(item) === pos && item !== '';
  })
  if (isNaN(Number(uniques[0])) && process.env.KONTENT_COLLECTION === 'default')
  uniques=uniques.sort()
  else
  uniques = uniques.sort(function(a, b){return b - a});

  if(defaultValue)
    uniques.unshift(defaultValue);

  return uniques;
}
