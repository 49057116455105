import { Media } from '@/components/media';
import { RichText } from '@/components/rich-text';
import { Button } from '@/components/button';
import React from 'react';
import classNames from 'classnames';

export const Article = ({
    id,
    codename,
    display,
    title,
    description,
    media,
    links,
    date,
    tags,
    override_read_more,
    mediaSizes,
}) => {
    return (
        <article
            className={classNames('article', {
                'article--list-thumbnail': display === 'list-thumbnail',
                'article--list': display === 'list',
                'article--card': display === 'card',
                'article--thumbnail-buttons': display === 'thumbnail-buttons',
                'article--three-column': display === 'three-column',
            })}
            aria-label={title}
            data-kontent-item-id={id}
            data-kontent-element-codename={codename}
        >
            {media && (
                <Media
                    wrapperClassName={'article__thumbnail'}
                    fill
                    objectFit={'cover'}
                    objectPosition={'center'}
                    media={media}
                    disableCaption
                    sizes={mediaSizes}
                />
            )}
            <div className="article__content">
                <div className="article__date">{date}</div>
                <div className="article__title">{title}</div>
                {description && (
                    <RichText
                        className="article__description"
                        data={description}
                    />
                )}
                {tags.length > 0 && (
                    <div className="article__tags">
                        {tags.map((tag, key) => {
                            if (tag.trim()) {
                                return (
                                    <div className="article__tag" key={key}>
                                        {tag.trim()}
                                    </div>
                                );
                            } else return null;
                        })}
                    </div>
                )}
                <div className="article__links">
                    {links?.map((link, index) => {
                        return (
                            <Button key={index} {...link}>
                                {override_read_more || link?.cta_text}
                            </Button>
                        );
                    })}
                </div>
            </div>
        </article>
    );
};
